import Vue from "@/libraries/vue";
import axios from "axios";
import { API_URL } from "@/assets/js/utils";
export default Vue.extend({
    name: "Articles",
    props: {
        articles: Array,
    },
    data() {
        const data = {};
        return data;
    },
    methods: {
        link(article) {
            return "/" + article.id;
        },
        view(article) {
            const id = article.id;
            axios.post(API_URL + "/articles/view/" + id);
        },
    },
});
