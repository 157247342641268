import axios from "axios";
import Vue from "@/libraries/vue";
import articles from "@/components/articles.vue";
import { API_URL } from "@/assets/js/utils";
export default Vue.extend({
    name: "Home",
    components: {
        articles,
    },
    async asyncData(ctx) {
        const req = API_URL + "/articles?attributes=id,title";
        const response = await axios.get(req, {});
        const articles = response.data;
        return { articles };
    },
    data() {
        const data = {};
        return data;
    },
});
